<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
        <div class="col col-lg-2"></div>
        <div class="col-md-auto mt-20">
          <rotate-square5></rotate-square5>
        </div>
        <div class="col col-lg-2"></div>
      </div>
      <div class="card card-body printableArea" v-else>
        <h3>
          <b>Disposisi</b>
          <!-- <span class="pull-right">#5669626</span> -->
        </h3>
        <hr>
        <div class="row">
          <div class="col-md-12">
            <Table
              :items="items"
              :header="headers"
              :onDetail="doDetail"
              :onEdit="doEdit"
              :onDelete="doDelete"
              :forbidEdit="forbidUpdate"
              :forbidDetail="forbidRead"
              :forbidDelete="forbidDel"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Table from "../../../components/table/table";
import { RotateSquare5 } from "vue-loading-spinner";

export default {
  components: {
    RotateSquare5,
    Table
  },
  data() {
    return {
      headers: {
        name: {
          label: "Judul",
          sortable: true
        },
        namaSingkat: {
          label: "Type",
          sortable: false
        },
        phone: {
          key: "age",
          label: "Jumlah Unit Disposisi",
          sortable: true
        }
      }
    };
  },
  computed: {
    items() {
      return this.$store.state.disposisi.items;
    },
    state() {
      return this.$store.state.disposisi;
    },
    forbidUpdate(){
      if(this.$store.state.profile.permissions.disposition){
        return !this.$store.state.profile.permissions.disposition.update;
      }
      return false;
    },
    forbidRead(){
      if(this.$store.state.profile.permissions.disposition){
        return !this.$store.state.profile.permissions.disposition.read;
      }
      return false;
    },
    forbidDel(){
      if(this.$store.state.profile.permissions.disposition){
        return !this.$store.state.profile.permissions.disposition.delete;
      }
      return false;
    }
  },
  mounted() {
    const state = {
      loaded: false,
      items: []
    };
    this.$store.commit("disposisi/STATE", state);
    this.get(state);
  },
  methods: {
    get() {
      this.$store.dispatch("disposisi/getDisposisi");
    },
    doDetail(val) {
      this.$store.dispatch("disposisi/onDetail", val);
    },
    doEdit(val) {
      this.$store.dispatch("disposisi/onEdit", val);
    },
    doDelete(val) {
      this.$store.dispatch("disposisi/onDelete", val);
    }
  }
};
</script>
